<template>
	<div>
		<v-container>
            <s-toolbar
                label="Abastecimiento"
                dark
                :color="'#8E8F91'"
            ></s-toolbar>
			<v-col cols="12" style="background-color: white;">
                <v-row>
                    <v-col cols="12" sm="6" md="2">
                        <s-select-definition
                            :def="1181"
                            label="Destino"
                            v-model="TypeDestiny"
                            @input="selectTypeDestiny($event)"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-row>
                            <v-col cols="8">
                                <s-text label="Correlativo" v-model="PltCorrelative"  @keyupEnter="searchChamber()"></s-text>
                            </v-col>
                            <v-col cols="2" class="d-flex align-end">
                                <v-btn color="primary" x-small fab @click="searchChamber()">
                                    <v-icon small>mdi-magnify</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <s-text label="ID - Correlativo" :value="IDCorrelative" readonly></s-text>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <s-text label="Cliente" v-model="PltClient" readonly></s-text>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <s-text label="Destino" v-model="PltDestiny" readonly></s-text>
                    </v-col>
                    <v-col cols="2" class="d-flex align-end">
                        <v-btn color="teal" small  @click="save()">
                            <v-icon small style="color: white;">mdi-send</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
			</v-col>
			<s-crud
                :config="config"
                :filter="filter"
                title="Pallets en cámara"
                ref="listPallet"
                searchInput
            >
                <template v-slot:filter>
                    <v-container>
                        <v-row>
                            <v-col>
                                <s-select-customer 
                                    clearable 
                                    label="Cliente" 
                                    v-model="filter.UwoCustomerID"
                                ></s-select-customer>
                            </v-col>
                            <v-col>
                                <s-select-definition
                                    :def="1181"
                                    label="Destino"
                                    clearable
                                    v-model="filter.TypeDestiny"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>

                <template v-slot:PltID="{ row }">
                        {{row.PltID + "-" + row.PltCorrelative}}
                </template>
                <template v-slot:ChaDescription="{ row }">
                    <v-chip color="success" x-small v-if="row.ChaID != 0">{{ row.ChaDescription }}</v-chip>
                </template>
                <template v-slot:CocChange="{ row }" >
                    <v-btn
                        v-if="row.ChaID != 0"
                        color="primary"
                        x-small
                        rounded
                        @click="clickCocChange(row)"
                        >
                        <i class="fas fa-edit"></i>
                    </v-btn>
                </template>
                <template v-slot:CocDateBegin="{ row }">
                    <v-btn
                        color="primary"
                        x-small
                        rounded
                        @click="clickCocDateBegin(row)"
                    >
                    <i class="far fa-calendar-times"></i>
                    </v-btn>
                </template>
                
                <template v-slot:AssignCamera="{ row }">
                    <v-btn
                        color="warning"
                        x-small
                        rounded
                        @click="AssignCamera(row)"
                        v-if="row.ChaID == 0"
                    >
                        <i class="fas fa-warehouse"></i>
                    </v-btn>
                </template>
			</s-crud>
            <v-dialog 
                v-if="dialogChange"
                v-model="dialogChange"
                persistent
                width="60%">
                <v-card>
                    <supply-chamber-change :chaID="chaIDModel" :PltID="pltID" @EndProcess="endProcessChange($event)"></supply-chamber-change>
                </v-card>
            </v-dialog>

            <v-dialog 
                v-if="dialogTimeChange"
                v-model="dialogTimeChange"
                persistent
                width="60%">
                <v-card>
                    <registration-time-change :CocDateBeginR="cocDateBegin" :CocID="cocID"  @EndProcess="endProcessChange($event)" ></registration-time-change>
                </v-card>
            </v-dialog>
            <v-dialog
                v-if="openDialog"
                v-model="openDialog"
                persistent
                width="70%">
                <chamber-warehouse-assign :value="CameraAssign" @close="closeDialog()"></chamber-warehouse-assign>
            </v-dialog>
		</v-container>
	</div>
</template>

<script>
	
    import sSelectCustomer from "@/components/Sale/SselectClientSap.vue";

	import _sPrfChamberWarehouse from "@/services/FreshProduction/PrfChamberWarehouseService";
    import _sPrfCoolingChamber from "@/services/FreshProduction/PrfCoolingChamberService";
	import SupplyChamberChange from "./SupplyChamberChange.vue";
	import RegistrationTimeChange from "./RegistrationTimeChange.vue";
	import ChamberWarehouseAssign from "./ChamberWarehouseAssign.vue";

	export default {
		components: {
			sSelectCustomer,
			SupplyChamberChange,
			RegistrationTimeChange,
            ChamberWarehouseAssign
		},

		props: {
			chamberSelected: null
		},

		data() {
			return {
				config: {
					model: {
						PltID: "ID",
						CocChange: "",
						CocDateBegin: "",
						AssignCamera: "",
                        ChaDescription: ""
					},
					service: _sPrfChamberWarehouse,
					headers: [
                        {text:"Asignar Camara", value: "AssignCamera"},
						{text:"Pallet", value: "PltID"},
						{text:"Cámara", value: "ChaDescription"},
						{text:"Horas Dentro", value: "TimeInside"},
						{text:"Cliente", value: "CardName"},
						{text:"Calibre", value: "TypeCaliberName"},
						{text:"Presentación", value: "PresentationName"},
						{text:"Cultivo", value: "TypeCultiveName"},
						{text:"Tipo Cultivo", value: "TypeCropName"},
						{text:"Variedad", value: "VarietyName"},
						{text:"Destino", value: "TypeDestinyPacking"},
						{text:"Fila", value: "ChrDescription"},
						{text:"Carril", value: "ChpPosition"},
						{text:"Columna", value: "ChcDescription"},
						{text:"Cambiar", value: "CocChange"},
						{text:"Editar Hora y Fecha", value: "CocDateBegin"},
					]
				},
				filter:{
					TypeDestiny: 0,
                    UwoCustomerID: 0
				},				
				cocID: 0,			
				cocDateBegin: "",
				dialogChange: false,
				dialogTimeChange: false,
                PltCorrelative: "",
                PltClient: "",
                PltDestiny: "",
                IDCorrelative: "",
                TypeDestiny: 0,
                ID: 0,
                Correlative: 0,
                chaID : 0,
                PltID : 0,
                chcID : 0,
                chrID : 0,
                chpID : 0,
                chaIDModel: 0,
                pltID : 0,
                openDialog: false,
                CameraDestiny: 0
			}
		},

		methods: {
            searchChamber () {
                console.log(this.TypeDestiny);
                _sPrfChamberWarehouse.getByPltCorrelative(this.PltCorrelative, this.TypeDestiny, this.$fun.getUserID())
				.then((resp) => {
                    if (resp.data.length == 0) {
                        this.$fun.alert("El Correlativo no Existe", "warning");
                        this.clear();
                        return;
                    }
					if (resp.status == 200) {
                        console.log(resp.data);
						this.ID = resp.data[0].PltID;
						this.Correlative = resp.data[0].PltCorrelative;
						this.IDCorrelative = this.ID + "-" + this.Correlative;
						this.PltClient = resp.data[0].UwoCustomerName;
						this.PltDestiny = resp.data[0].TypeDestinyPacking;
					}
				});
            },
            clear() {
                this.PltCorrelative = "";
                this.PltDestiny = "";
                this.IDCorrelative = "";
                this.PltClient = "";
            },
            save(){
                if (this.ID == 0 || this.IDCorrelative == "" || this.PltCorrelative == "") {
                    this.$fun.alert("Busque un Pallet para poder Guardar", "warning");
                    return;
                }
				this.$fun.alert("¿Seguro de pasar a Cámara?", "question")
					.then((val) => {
						if (val.value) {
							let coc = {
								PltID: this.ID,
								ChaID: this.chaID,
								ChcID: this.chcID,
								ChrID: this.chrID,
								ChpID: this.chpID,
								CocStatus: 1,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(),
							};

							_sPrfCoolingChamber
								.save(coc, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) { 
											this.$fun.alert( "Guardado Correctamente", "success" );
                                            this.$refs.listPallet.refresh();
                                            this.clear();
										}
									});
						}
					}) 
			},
			endProcessChange( coc ){
				this.dialogChange = false;
				this.dialogTimeChange = false;
				if (coc != null) {
					this.$refs.listPallet.refresh();
				}
			},
			closeDialogAdmin() {
				this.$emit("closeDialogAdmin")
			},
			clickCocChange( item ){
                if (item.ChaID == 0) {
                    this.$fun.alert("Asigne una camara al pallet", "warning");
                    return;
                }
				this.pltID = item.PltID;
                this.chaIDModel = item.ChaID;
				this.dialogChange = true;
			},

			clickCocDateBegin( item ){
				console.log(item);				
				this.cocID = item.CocID;	
				this.cocDateBegin = this.$moment(new Date(item.CocDateBegin)).format("DD-MM-YYYY HH:mm:SS");
				this.dialogTimeChange = true;
			},
            AssignCamera( item ){
                this.CameraAssign = item;
                this.openDialog = true;
            },
            closeDialog(){
                this.openDialog = false;
                this.$refs.listPallet.refresh();
            },
            selectTypeDestiny( event ){
                this.PltDestiny = "";
                this.IDCorrelative = "";
                this.PltClient = "";
            },
		},

		created () {
		},
	}
</script>