<template>
	<v-card dense>
	  <s-toolbar 
		label="Para seleccionar una opcion, Pulse el boton:" 
		dark 
		color="#8e8f91" 
		close 
		@close="close()" 
		
	  ></s-toolbar>
	  <v-container>
		<v-row>
		  <v-col cols="12" md="4" v-for="col in items" :key="col.ChaID">
			<v-card
			  :color="selectedItem && selectedItem.ChaID === col.ChaID ? 'success lighten-1' : 'grey lighten-4'"
			  class="pa-2 mx-1 transition-swing"
			  :elevation="selectedItem && selectedItem.ChaID === col.ChaID ? 6 : 2"
			  hover
			  @click="selectItem(col)"
			  style="cursor: pointer; min-height: 90px; position: relative; overflow: hidden"
			>
			  <div class="d-flex flex-column align-center">
				<v-icon 
				  :color="selectedItem && selectedItem.ChaID === col.ChaID ? 'white' : 'amber darken-2'" 
				  size="36"
				  class="mb-1"
				>
				  mdi-refrigerator
				</v-icon>
				<div 
				  class="text-center text-subtitle-2 font-weight-bold"
				  :class="selectedItem && selectedItem.ChaID === col.ChaID ? 'white--text' : ''"
				>
				  {{ col.ChaDescription }}
				</div>
				<div 
				  class="text-center caption"
				  :class="selectedItem && selectedItem.ChaID === col.ChaID ? 'white--text' : 'grey--text text--darken-1'"
				>
				  {{ col.TypeFreshProductionDestinationText }}
				</div>
				
				<!-- Icono de tipo de instalación -->
				<v-icon 
				  :color="selectedItem && selectedItem.ChaID === col.ChaID ? 'white' : 'blue-grey'" 
				  size="16"
				  class="mt-1"
				>
				  {{ getTypeIcon(col) }}
				</v-icon>
			  </div>
			  
			  <v-icon
				v-if="selectedItem && selectedItem.ChaID === col.ChaID"
				color="white"
				size="18"
				style="position: absolute; top: 5px; right: 5px"
			  >
				mdi-check-circle
			  </v-icon>
			</v-card>
		  </v-col>
		</v-row>
	  </v-container>
	</v-card>
</template>

<script>

    import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";
	import _sPrfChamberWarehouse from "@/services/FreshProduction/PrfChamberWarehouseService";

	export default {
		components: {},
		props:{
			value: null,
        },
		data() {
			return {
				radios: null,
				selectedItem: null,
				items: [
					// Aquí van tus datos de ejemplo
				],
				TypeDestiny: 0,
			};
		},
		methods: {
			change(val) {
				this.items = val;
			},
			 
			selectItem(item) {
				this.selectedItem = item; // Guarda el item seleccionado
				this.save();
			},
			save() {
				
				if (this.selectedItem == null) {
					this.$fun.alert("Seleccione un elemento", "warning");
					return;
				}

				const ValueChaID = this.selectedItem.ChaID;
				console.log("ValueChaID", ValueChaID);

				console.log("Elemento seleccionado:", this.selectedItem);
				this.$fun.alert(`¿Seguro de pasar a ${this.selectedItem.ChaDescription} - ${this.selectedItem.TypeFreshProductionDestinationText}?`, "question")
				.then((val) => {
					if (val.value) {
						let coc = {
							PltID: this.value.PltID,
							ChaID: ValueChaID,
							CocID: this.value.CocID,
							CocStatus: 1,
							SecStatus: 1,
							UsrUpdateID: this.$fun.getUserID(),
						};

						console.log("COC", coc);

						_sPrfChamberWarehouse
							.save(coc, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) { 
										this.$fun.alert( "Guardado Correctamente", "success" );
										this.$emit("close");
									}
								});
					}
				}) 
			},
			list(TypeDestiny){
				_sPrfChamberService.list({TypeDestiny},this.$fun.getUserID())
                .then(r => {
					console.log(r.data);
                   	this.items = r.data;
                })
			},
			close() {
				this.$emit("close");
			},
			getTypeIcon(item) {
				if (item.TypeChamberTunnel === 1) {
					return 'fas fa-warehouse'; // Para cámaras
				} else if (item.TypeChamberTunnel === 2) {
					return 'mdi-tunnel'; // Para túneles
				} else if (item.TypeFreshProductionDestination === 1 && item.TypeFreshProductionDestinationText === "EUROPA") {
					return 'mdi-map-marker-radius'; // Para EUROPA
				} else {
					return 'mdi-warehouse'; // Por defecto, almacén
				}
			}
		},
		created() {
			this.list(this.value.TypeDestinyValue);
		},
	};
</script>
