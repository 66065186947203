import Service from '../Service';

const resource = "PrfChamberWarehouse/"

export default {

    pagination(dtr, requestID) {
        return Service.post(resource + 'pagination', dtr, {
            params: { requestID }
        });
    },
    getByPltCorrelative(PltCorrelative, TypeDestiny, requestID) {
        return Service.post(resource + "getByPltCorrelative", {}, {
            params: { PltCorrelative:PltCorrelative, TypeDestiny:TypeDestiny, requestID: requestID },
        });
    },
    save(coc, requestID) {
        return Service.post(resource + 'save', coc, {
            params: { requestID }
        });
    },
}